









import { Component, Prop, Vue } from 'vue-property-decorator'
import VueRecaptcha from 'vue-recaptcha'

// there is a script tag in the header of the index file
@Component({
  components: {
    VueRecaptcha
  }
})

export default class ReCaptcha extends Vue {
  siteKey = '6LcAvhYUAAAAACGb6SUU9YcISP5VYXowjfEDbSAy'

  @Prop({ required: true })
  private readonly value!: string | null

  onVerify (response: string): void {
    this.$emit('input', response)
  }
}

